<template>
  <section class="content">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card card-primary">
          <form role="form" @submit="submitForm">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 ml-auto mr-auto form-group">
                  <label class="control-label">NOMOR SISWA</label>
                  <v-select
                    label="nomor"
                    name="nomor"
                    :filterable="false"
                    :options="nomorSiswa"
                    v-model="form.siswa_registrasi_id"
                    :reduce="(opt) => opt.id"
                    @search="onSearch"
                    @input="setSelected"
                    placeholder="Ketik nomor siswa"
                  >
                    <template slot="no-options"> Tidak ada... </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.label }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.label }}
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-5 col-md-4">
                  <div class="col-md-12 form-group">
                    <label class="control-label">NAMA</label>
                    <input
                      id="nama_siswa"
                      class="form-control"
                      v-model="form.nama_siswa"
                      type="text"
                      required="required"
                      name="nama_siswa"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">ALAMAT</label>
                    <input
                      id="addr_detail"
                      class="form-control"
                      v-model="form.addr_detail"
                      type="text"
                      required="required"
                      name="addr_detail"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">TEMPAT/TANGGAL LAHIR</label>
                    <input
                      id="birth_date"
                      class="form-control"
                      type="text"
                      required="required"
                      name="birth_date"
                      v-model="ttl"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">NO. HP</label>
                    <input
                      id="phone"
                      class="form-control"
                      v-model="form.phone"
                      type="text"
                      required="required"
                      name="phone"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-xl-5 col-md-4">
                  <div class="col-md-12 form-group">
                    <label class="control-label">EMAIL</label>
                    <input
                      id="email"
                      class="form-control"
                      v-model="form.email"
                      type="text"
                      required="required"
                      name="email"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">ASAL SEKOLAH</label>
                    <input
                      id="asal_sekolah"
                      class="form-control"
                      v-model="form.asal_sekolah"
                      type="text"
                      required="required"
                      name="asal_sekolah"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">JURUSAN IPA/IPS</label>
                    <input
                      id="jurusan_sekolah"
                      class="form-control"
                      v-model="form.jurusan_sekolah"
                      type="text"
                      required="required"
                      name="jurusan_sekolah"
                      readonly
                    />
                  </div>
                  <div class="col-md-12 form-group">
                    <label class="control-label">LULUS TAHUN</label>
                    <input
                      id="lulus_sekolah"
                      class="form-control"
                      v-model="form.lulus_sekolah"
                      type="text"
                      required="required"
                      name="lulus_sekolah"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-xl-2 col-md-3">
                  <div class="card-body p-1 user-profile">
                    <img
                      style="object-fit: cover; height: 240px; width: 100%"
                      alt="image"
                      id="imgPreview"
                      class="img-fluid img-responsive"
                      :src="url + '/uploads/default/unknown_user.jpg'"
                      v-if="!form.img_code"
                    />
                    <img
                      style="object-fit: cover; height: 240px; width: 100%"
                      alt="image"
                      id="imgPreview"
                      class="img-fluid img-responsive"
                      :src="url + '/uploads/profile_upload/' + form.img_code"
                      v-if="form.img_code"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <label for="is_pengajuan_angsuran"
                >MENGAJUKAN ANGSURAN PEMBAYARAN</label
              >
              <div class="col-md-6 form-group">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="is_pengajuan_angsuran"
                    id="is_pengajuan_angsuran"
                    v-model="form.is_pengajuan_angsuran"
                    @change="onChange($event)"
                    value="true"
                  />
                  <label class="form-check-label" for="is_pengajuan_angsuran"
                    >YA</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="is_pengajuan_angsuran"
                    id="is_pengajuan_angsuran"
                    v-model="form.is_pengajuan_angsuran"
                    @change="onChange($event)"
                    value="false"
                  />
                  <label class="form-check-label" for="is_pengajuan_angsuran"
                    >TIDAK</label
                  >
                </div>
              </div>
              <label for="labelskema_bayar" id="labelskema_bayar"
                >RENCANA PEMBAYARAN</label
              >
              <div class="col-md-4 form-group" id="skema_bayar" v-if="false">
                <label class="control-label">WAKTU</label>
                <datepicker v-model="form.waktu" />
              </div>
              <div class="col-md-4 form-group" id="skema_bayar2" v-if="false">
                <label class="control-label">NOMINAL</label>
                <input
                  id="nominal"
                  class="form-control"
                  v-model="form.nominal"
                  type="number"
                  name="nominal"
                />
              </div>
              <div class="col-md-4 form-group" id="skema_bayar3" v-if="false">
                <label class="control-label">JUMLAH ANGSURAN</label>
                <input
                  id="jumlah_angsuran"
                  class="form-control"
                  v-model="form.jumlah_angsuran"
                  type="number"
                  name="jumlah_angsuran"
                />
              </div>
              <div class="form-group mt-4" v-if="false">
                <label for="keterangan">Alasan</label>
                <textarea
                  class="form-control"
                  id="keterangan"
                  rows="3"
                  v-model="form.keterangan"
                  placeholder="Tuliskan alasan untuk pengajuan angsuran pembayaran"
                ></textarea>
              </div>
              <div class="tbl">
                <button class="btn btn-primary add" v-on:click="addItem">Tambah item</button>
                <div class="col-6">
                  <table class="table table-hover" ref="tableuser" id="tblform">
                    <thead id="tblform2">
                        <tr>
                            <th>Tanggal</th>
                            <th>Nominal</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody id="tblform3">
                    </tbody>
                  </table>
                </div>
              </div>
              <button class="btn btn-default save" v-on:click="simpanData" v-if="false">Simpan Data</button>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-primary mr-1">Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { authFetch, createTable } from "@/libs/hxcore";
import datepicker from "@/components/Datepicker";
import Swal from "sweetalert2";
import $ from "jquery";
// import ToggleButton from "vue-js-toggle-button";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.use(datepicker);

export default {
  data() {
    return {
      nomorSiswa: [],
      ttl: "",
      url: "",
      form: {
        nomor_pendaftaran: "",
        code: "",
        is_pengajuan_angsuran: "",
        siswa_registrasi_id: "",
        nama_siswa: "",
        addr_detail: "",
        birth_date: "",
        birth_place: "",
        phone: "",
        email: "",
        asal_sekolah: "",
        jurusan_sekolah: "",
        lulus_sekolah: "",
        img_code: "",
        nominal: "",
        jumlah_angsuran: "",
        waktu: "",
        keterangan: "",
      },
      formItem: {
        waktu: '',
        nominal: '',
      }
    };
  },
  created: function () {
    this.url = window.$apiUrl;
    authFetch("/pr/siswa_plan_angsuran/nomor_siswa").then((res) => {
      res.json().then((json) => {
        //get default address
        if (json.success) {
          if (!json.data) {
            this.nomorSiswa.push({
              id: 0,
              label: "-",
            });
          } else {
            this.nomorSiswa = json.data
          }
        }
      });
    });
  },
  methods: {
    addItem: function(ev) {
            var nRow = this.tbl.fnAddRow(this.formItem);
            var aData = {
              waktu: this.formItem.waktu,
              nominal: this.formItem.nominal,
            };
            this.tbl.fnUpdate(aData, nRow);
            $('td .btn.edit', nRow).click();
            ev.preventDefault();
        },
    simpanData: function() {
      var data = this.tbl.getSerialize();
      console.log(data)
    },
    setSelected(value) {
      const self = this;
      self.form.siswa_registrasi_id = value;
      let id = self.form.siswa_registrasi_id;
      self.loadSiswa(id);
    },
    onChange(event) {
      if (event.target.value == "true") {
        $("#labelskema_bayar").show();
        $(".tbl").show();
        $(".add").show();
        $(".save").show();
      } else {
        Swal.fire({
          title: "Konfirmasi",
          text: "Perubahan ini mengharuskan entri kembali item pembayaran.",
          showCancelButton: true,
          confirmButtonText: `Lanjutkan`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.tbl.api().clear().draw();
            $("#labelskema_bayar").hide();
            $(".tbl").hide();
            $(".add").hide();
            $(".save").hide();
          } else {
            this.form.is_pengajuan_angsuran = true;
          }
        });
        // $(this.$refs.tableuser).hide();
      }
    },
    onSearch(search, loading) {
      if (search.length > 3) {
        loading(true);
        var vm = this;
        authFetch(
          `/pr/siswa_plan_angsuran/cari_siswa?q=${escape(search)}`
        ).then((res) => {
          res.json().then((json) => (vm.nomorSiswa = json.items));
          loading(false);
        });
      }
    },
    loadSiswa(id) {
      authFetch("/pr/siswa_plan_angsuran/nomor_siswa/" + id).then((res) => {
        res.json().then((json) => {
          //get default address
          if (json.success) {
            this.form = {
              siswa_registrasi_id: json.data.id,
              nama_siswa: json.data.nama_siswa,
              addr_detail: json.data.addr_detail,
              birth_date: json.data.birth_date,
              birth_place: json.data.birth_place,
              phone: json.data.phone,
              email: json.data.email,
              asal_sekolah: json.data.asal_sekolah,
              jurusan_sekolah: json.data.jurusan_sekolah,
              lulus_sekolah: json.data.lulus_sekolah,
              img_code: json.data.img_code,
            };
            this.ttl = `${json.data.birth_place} / ${json.data.birth_date}`;
          }
        });
      });
    },
    submitForm: function (ev) {
      const e = this.$refs;
      const self = this;

      var items = [];
      this.tbl
        .api()
        .rows()
        .every(function (rowIdx, tableLoop, rowLoop) {
          var d = this.data();
          items.push(d);
        });

      var allData = { group: self.form, items: items };

      var urlSubmit = "/pr/siswa_plan_angsuran";
      //   if (this.method == "PUT")
      //     urlSubmit = "" + this.form.id;

      authFetch(urlSubmit, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.$router.push("/siswa-plan-angsuran");
        });

      ev.preventDefault();
    },
  },
  components: {
    vSelect,
    datepicker,
  },
  mounted() {
    if (this.form.is_pengajuan_angsuran == '') {
      $("#labelskema_bayar").hide();
      $(".tbl").hide();
      $(".add").hide();
      $(".save").hide();
    }
        const e = this.$refs;
        this.tbl = createTable(e.tableuser, {
            title: "",
            serverSide: false,
            filter: false,
            ajax: "/pr/siswa_plan_angsuran/skemabayar",
            order: [[ 0, "desc" ]],
            lengthChange: false,
            paging: false,
            info: false, 
            columns: [
                { data: "waktu",
                  type: "date"},
                { data: "nominal" },
                { data: "action",
                sortable: false,
                render: function (data, row, type, meta) {
                  return '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>';
                } },
            ],
            rowCallback: function (row, data) {}
        }).fnEditor({
            action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
        })
    }
};
</script>
<style scoped>
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  /*background-color: black;*/
  display: inline-block;
}

.inputfile + label {
  cursor: pointer;
  /* "hand" cursor */
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.user-profile .center-profile {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  transition: opacity 0.3s;
  line-height: 34px;
  text-align: center;
  text-decoration: none;
}

.user-profile .btn-profile {
  display: block;
  width: 36px;
  height: 36px;
  transition: opacity 0.3s;
  border-radius: 50%;
  background-color: #fff;
  color: #606975;
  line-height: 34px;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}

.user-profile .remove-profile {
  /*visibility: hidden !important;*/
  cursor: pointer;
}
</style>